// -- options --

$color-head: #bd063d; //- цвет заголовка
$color-close: #bd063d; //- цвет закрыть
$color-bg: #050b16; //- цвет подложки

.cooperation-order {
    overflow: hidden;
    &__head {
        color: #000000;
        font-family: 'Roboto Slab', sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 20px;
        background: transparent;
        border: none;
        border-radius: 0;
        box-shadow: none;
        margin: 0px 0px 15px;
    }

    &__form {
        padding: 0px;
    }

    &__container {
        // position: absolute;
        // // @include centered();
    }

    &__box {
        margin-bottom: 30px;
    }

    &__information {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-left: -15px;
        margin-right: -15px;
        box-sizing: border-box;
        & * {
            box-sizing: border-box;
        }
        & .cooperation-order {
            &__form-field,
            &__select,
            &__checkbox-box,
            &__radiobox-box,
            &__button-box {
                width: 33.33%;
                padding: 15px;
            }
            &__attracted-products-box {
                width: 100%;
            }
            &__checkbox-box {
                align-self: center;
            }
        }
    }

    &__brends-sell-myself-box {
        display: none;
    }

    &__form-field {
    }

    &__checkbox {
        margin-top: 17px;
    }

    &__button-box {
        text-align: right;
    }

    &__button {
        color: #ffffff;
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size: 14px;
        background: #f69a73;
        border-radius: 20px;
        border-width: 0px;
        border-style: solid;
        border-color: #f69a73;
        box-shadow: none;
        padding: 12px 60px 12px 60px;
        cursor: pointer;
        display: inline-block;
        border-bottom: 4px solid #dd815a;
        text-align: center;
        text-transform: none !important;
        transition-property: all;
        transition-duration: 0.5s;
        &:hover {
            background: #dd815a;
            border-bottom: 4px solid #f69a73;
        }
    }

    @include xl() {
    }
    @include lg() {
        &__information {
            margin-left: -5px;
            margin-right: -5px;
            & .cooperation-order {
                &__form-field,
                &__select,
                &__checkbox-box,
                &__radiobox-box,
                &__button-box {
                    padding: 5px;
                }
            }
        }
    }
    @include md() {
        &__checkbox {
            margin-top: 0px;
        }
        &__information {
            & .cooperation-order {
                &__form-field,
                &__select,
                &__checkbox-box,
                &__radiobox-box,
                &__button-box {
                    width: 50%;
                }
            }
        }
    }
    @include sm() {
        &__information {
            & .cooperation-order {
                &__form-field,
                &__select,
                &__checkbox-box,
                &__radiobox-box,
                &__button-box {
                    width: 100%;
                }
            }
        }
    }
    @include xs() {
    }
}

#cooperation-order-attracted-products {
    height: 39px;
}
