// -- options --

$field-bg: #fff; //- цвет поля
$field-error: #f00; //- цвет ошибки

.form-field {
    &__edit {
        color: #666666 !important;
        font-family: 'Roboto', sans-serif !important;
        font-weight: normal;
        font-style: normal;
        font-size: 14px;
        border-radius: 20px;
        border-width: 0px;
        border-style: solid;
        border-color: #cfd9db;
        box-shadow: none;
        background: #e9eff6;
        padding: 10px 20px 10px 20px !important;
        outline: none;
        box-sizing: border-box;
        width: 100%;
        border: solid 1px transparent;
        resize: none;
        margin-bottom: 0px !important;
    }

    &__name {
        display: block;
        color: #707981;
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size: 14px;
        text-align: left;
        word-wrap: break-word;
        margin: 0 0 3px;
        padding-left: 13px;
        & span {
            color: $field-error;
            font-weight: bold;
            font-size: 15px;
        }
    }

    &__icon {
        position: absolute;
        @include centered(y);
    }

    &_phone {
        padding-left: 20px;
        @include md() {
            padding-left: 0px;
        }
    }

    & .error {
        border: solid 1px $field-error;
    }
}

@include placeholder('form-field__edit', #ccc);

@include placeholder('form-field__edit.error', rgb(233, 41, 41));
