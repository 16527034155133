// -- options --

$field-bg: #e2e2e2; //- цвет чекбокса
// $field-check-bg: #ca0741; //- цвет выделенного чекбокса
$field-error: #f00; //- цвет ошибки

.checkbox {
    position: relative;
    display: block;

    &__input {
        display: none;
    }

    &__name {
        display: block;
        color: #707981;
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size: 14px;
        text-align: left;
        word-wrap: break-word;
        margin: 0 0 3px;
        padding-left: 13px;
        & span {
            color: $field-error;
            font-weight: bold;
            font-size: 15px;
        }
    }

    &__custom {
        position: absolute;
        width: 17px;
        height: 17px;
        background: #fff;
        margin-right: 10px;
        transition-property: background-color;
        transition-duration: 0.7s;
        display: block;
        top: 0px;
        border-style: solid;
        border-color: #cfd9db;
        border-width: 1px !important;
        border-radius: 5px;
    }

    &__custom,
    &__label {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
    }

    &__label {
        padding-left: 32px;
        color: #707981;
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size: 14px;
        & span {
            color: $field-error;
            font-weight: bold;
            font-size: 15px;
        }
        & a {
            /*display: inline-block;
            margin-top:5px;*/
        }
    }

    &__input:checked + &__custom {
        background: #2c97de;
        border-color: #2c97de;
        &:before {
            content: '\2714';
            display: block;
            font-size: 10px;
            position: absolute;
            @include centered();
            color: #fff;
            font-weight: bold;
        }
    }

    & .error-checked {
        & .checkbox__custom {
            background-color: $field-error;
            border-color: $field-error;
        }

        & .checkbox__label {
            color: $field-error;
        }
    }
}
