.main-sale {
    background: transparent;
    margin-bottom: 50px;
    & .wrap {
        padding: 0px;
    }
    & .mctitle {
        margin-top: 0px;
        margin-bottom: 20px;
        background: url(../img/percent.jpg) left center no-repeat;
    }

    &__content {
        // height: 300px;
        overflow-y: auto;
        & table {
            width: 100%;
            & th {
                text-align: left;
                border-bottom: 3px solid #145390;
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 16px;
            }
        }
    }

    & span {
        position: relative;
        text-decoration: none !important;
        // &:before {
        //     content: '';
        //     width: 100%;
        //     height: 1px;
        //     background-color: rgb(255, 0, 0);
        //     position: absolute;
        //     top: 50%;
        //     left: 0px;
        //     transform: rotate(-25deg);
        // }
        &:after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: rgb(255, 0, 0);
            position: absolute;
            top: 50%;
            left: 0px;
            transform: rotate(25deg);
        }
    }

    @include xl() {
    }
    @include lg() {
    }
    @include md() {
        &__content {
            height: auto;
            & table {
                & tr {
                    & th {
                        font-size: 14px;
                        line-height: 1.2;
                    }
                    & td {
                        padding-top: 7px;
                        padding-bottom: 7px;
                    }
                }
            }
        }
    }
    @include sm() {
        &__content {
            & table {
                & tr {
                    & th {
                        font-size: 12px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                    & td {
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                }
            }
        }
    }
    @include xs() {
    }
}

// &::-webkit-scrollbar {
//     width: 3px;
//     height: 3px;
// }
// &::-webkit-scrollbar-button {
//     background-color: #666;
//     display: none;
// }
// &::-webkit-scrollbar-track {
//     background-color: #999;
// }
// &::-webkit-scrollbar-track-piece {
//     background-color: #f1f1f1;
// }
// &::-webkit-scrollbar-thumb {
//     background-color: #145390;
//     border-radius: 3px;
// }
// &::-webkit-scrollbar-corner {
//     background-color: #999;
// }
// &::-webkit-resizer {
//     background-color: #666;
// }
