.success-message {
    color: #000;
    @include centered();
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.5);
    display: none;

    &__wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__box {
        background: #fff;
        border: solid 2px #fe5d27;
        padding: 20px 40px;
        text-align: center;
        border-radius: 10px;
    }

    &__text {
        font-size: 17px;
        padding-bottom: 10px;
    }

    &__button {
        margin-top: 10px;
        border-color: #fe5d27 !important;
        color: #fe5d27 !important;
    }
}
