// -- options --

$field-bg: #fff; //- цвет поля
$field-error: #f00; //- цвет ошибки

.select {
    &__edit {
        color: #666666 !important;
        font-family: 'Roboto', sans-serif !important;
        font-weight: normal;
        font-style: normal;
        font-size: 14px;
        border-radius: 20px;
        border-width: 0px;
        border-style: solid;
        border-color: #cfd9db;
        box-shadow: none;
        padding: 10px 20px 10px 20px !important;
        outline: none;
        box-sizing: border-box;
        width: 100%;
        border: solid 1px transparent;
        resize: none;
        margin-bottom: 0px !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none !important;
        position: relative;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='rgb(207, 207, 207)' fill-rule='evenodd' class='cls-1' d='M8,0L4.141,6.993,0.012,0.156Z'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-color: #e9eff6;
        background-position: right 13px center;
        cursor: pointer;
    }

    &__name {
        display: block;
        color: #707981;
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size: 14px;
        text-align: left;
        word-wrap: break-word;
        margin: 0 0 3px;
        padding-left: 13px;
        & span {
            color: $field-error;
            font-weight: bold;
            font-size: 15px;
        }
    }

    &__icon {
        position: absolute;
        @include centered(y);
    }

    &_phone {
        padding-left: 20px;
        @include md() {
            padding-left: 0px;
        }
    }

    & .error {
        border: solid 1px $field-error;
    }
}

@include placeholder('select__edit', #ccc);

@include placeholder('select__edit.error', rgb(233, 41, 41));
