.call-back-pont {
  position: fixed;
  z-index: 10;
  background: rgb(254, 93, 39);
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border-radius: 5px 5px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
  padding: 0px 15px;
  visibility: visible;
  right: 0px;
  transform: rotate(-90deg);
  top: 50%;
  width: 180px;
  margin-top: -18px;
  margin-right: -66px;
  overflow: hidden;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  // font-size: 0px;
  // width: 5px;
  // height: 5px;
  // bottom: 0px;
  // padding: 0px;

  @include xxl() {
    top: auto;
    left: auto;
    right: 0px;
    bottom: 0px;
    font-size: 12px;
    font-weight: 400;
    width: 90px;
    line-height: 1.1;
    height: 36px;
    transform: rotate(0deg);
    @include centered(x);
    position: fixed;
    margin-left: 100px;
  }
  @include xl() {
  }
  @include lg() {
  }
  @include md() {
  }
  @include sm() {
  }
  @include xs() {
  }
}
