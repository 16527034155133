// -- grids --

$xs: 420px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1260px;
$xxl: 1390px;

// body {
//   height: 3000px;
// }

// -- settings --
@import "settings/mixins.scss";

// -- components --
@import "components/call-back-pont.scss";
@import "components/callback-left.scss";
@import "components/forms/checkbox.scss";
@import "components/forms/cooperation-order.scss";
@import "components/forms/form-field.scss";
@import "components/forms/popup-callback.scss";
@import "components/forms/popup-review.scss";
@import "components/forms/popup-subscribe.scss";
@import "components/forms/preloader.scss";
@import "components/forms/radio.scss";
@import "components/forms/select.scss";
@import "components/forms/success-message.scss";
@import "components/main-sale/main-sale.scss";

.overflow_hidden {
  overflow: hidden;
  & .popup-callback-open {
    right: 17px;
    @include lg() {
      right: 0px;
    }
  }
  & .mm-page {
    padding-right: 17px;
    @include lg() {
      padding-right: 0px;
    }
  }
}
