// -- options --

$field-bg: #e2e2e2; //- цвет чекбокса
// $field-check-bg: #ca0741; //- цвет выделенного чекбокса
$field-error: #f00; //- цвет ошибки

.radio {
  position: relative;
  display: block;
  margin: 6px 0px;

  &__input {
    display: none;
  }

  &__name {
    display: block;
    color: #707981;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    text-align: left;
    word-wrap: break-word;
    margin: 0 0 3px;
    padding-left: 13px;
    & span {
      color: $field-error;
      font-weight: bold;
      font-size: 15px;
    }
  }

  &__custom {
    position: absolute;
    width: 18px;
    height: 18px;
    display: block;
    top: 0px;

    border-radius: 20px;
    border-width: 0px;
    border-style: solid;
    border-color: #cfd9db;
    border-width: 1px !important;
    box-shadow: none;
    background: transparent;
    transition-property: all;
    transition-duration: 0.7s;
  }

  &__custom,
  &__label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }

  &__label {
    padding-left: 32px;
    color: #707981;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    & a {
      /*display: inline-block;
            margin-top:5px;*/
    }
  }

  &__input:checked + &__custom {
    background-color: #2c97de;
    border-color: #2c97de;
    &:before {
      content: '';
      display: block;
      position: absolute;
      @include centered();
      width: 6px;
      height: 6px;
      border-radius: 100px;
      background: #fff;
      font-weight: bold;
    }
  }

  & .error-checked {
    & .radio__custom {
      background-color: mix(#fff, $field-error, 70%);
    }

    & .radio__label {
      color: $field-error;
    }
  }
}
